// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-auth-0-src-pages-auth-callback-tsx": () => import("./../../../node_modules/gatsby-theme-auth0/src/pages/auth/callback.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-auth-0-src-pages-auth-callback-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-playlists-js": () => import("./../../../src/pages/playlists.js" /* webpackChunkName: "component---src-pages-playlists-js" */),
  "component---src-pages-searchresults-js": () => import("./../../../src/pages/searchresults.js" /* webpackChunkName: "component---src-pages-searchresults-js" */),
  "component---src-pages-waveform-generator-js": () => import("./../../../src/pages/waveformGenerator.js" /* webpackChunkName: "component---src-pages-waveform-generator-js" */),
  "component---src-templates-music-js": () => import("./../../../src/templates/music.js" /* webpackChunkName: "component---src-templates-music-js" */),
  "component---src-templates-playlist-js": () => import("./../../../src/templates/playlist.js" /* webpackChunkName: "component---src-templates-playlist-js" */),
  "component---src-templates-song-js": () => import("./../../../src/templates/song.js" /* webpackChunkName: "component---src-templates-song-js" */)
}

